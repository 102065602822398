<template>
  <div class="login_container">
    <el-row class="login_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <el-row class="loginTip">
      <h1>请选择您的读卡方式</h1>
    </el-row>
    <div class="login_main">
      <div class="swipe" @click="ReadIDCard">
        <input name="ReadCard" type="button" value="刷身份证" />
      </div>
      <div class="input" @click="input">
        <input type="button" value="手工输入" />
      </div>
      <!-- <div class="chs" @click="DdIDCard">
        <input type="button" value="医保电子凭证" />
      </div>
      <div class="Wchs" @click="ReadIDCard">
        <input type="button" value="外籍身份证" />
      </div> -->
      <div v-if="name == '自助报道'" class="code" @click="code">
        <input type="button" value="扫描条形码" />
      </div>
    </div>
    <el-button class="home" type="primary" @click.native="home">首页</el-button>
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { userInfoInHis } from '@/service/api'
import qs from 'qs'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      name: '',
      certNbr: '',
      count: '',
      timer: ''
    }
  },
  created() {
    this.name = this.$route.query.name
    if (window.localStorage.getItem('cardNo')) {
      this.tiaoz({
        cardNo: window.localStorage.getItem('cardNo'),
        name: window.localStorage.getItem('name'),
        patCardNo: window.localStorage.getItem('patCardNo'),
        patCardType: window.localStorage.getItem('patCardType')
      })
    }
  },

  methods: {
    DdIDCard() {
      this.$router.push({
        path: '/cdCrs',
        query: {
          name: this.name
        }
      })
    },
    home() {
      this.$router.push('/home')
    },
    ajax(url) {
      const p = new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('POST', url, false)
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if ((xhr.status >= 200 && xhr.status < 300) || xhr.status === 304) {
              resolve(JSON.parse(xhr.response))
            } else {
              reject(new Error('Response error'))
            }
          }
        }
        xhr.send(null)
      })
      return p
    },
    ReadIDCard() {
      this.ajax('http://127.0.0.1:8789/api/readCard').then(res => {
        console.log(res)
        if (res.errorMsg) {
          this.$message('请将身份证放置在读卡区')
        }
        let data = {
          cardNo: res.resultContent.certNumber,
          cardType: '1',
          isSelf: '1'
        }
        userInfoInHis(qs.stringify(data)).then(re => {
          console.log(re)
          if (!re.data.code == 0) {
            this.$message({
              message: '请先建档',
              type: 'warning'
            })
            this.$router.push({
              path: '/file',
              query: {
                certNumber: res.resultContent.certNumber,
                partyName: res.resultContent.partyName,
                gender: res.resultContent.gender
              }
            })
          } else {
            window.localStorage.setItem('name', re.data.data.name)
            window.localStorage.setItem('cardNo', re.data.data.cardNo)
            window.localStorage.setItem('patCardNo', re.data.data.patCardNo)
            window.localStorage.setItem('patCardType', re.data.data.patCardType)
            this.tiaoz(re.data.data)
          }
        })
      })
    },
    tiaoz(data) {
      if (this.name === '当天挂号' || this.name === '预约挂号') {
        this.$router.push({
          path: '/register',
          query: {
            name: this.name,
            patName: data.name,
            cardNo: data.cardNo,
            patCardNo: data.patCardNo,
            patCardType: data.patCardType
          }
        })
      } else if (this.name === '门诊缴费') {
        this.$router.push({
          path: '/outpatient',
          query: {
            name: this.name,
            patName: data.name,
            patCardNo: data.patCardNo,
            patCardType: data.patCardType,
            MZH: data.MZH
          }
        })
      } else if (this.name === '报告打印') {
        this.$router.push({
          path: '/report',
          query: {
            name: this.name,
            patName: data.name
          }
        })
      } else if (this.name === '住院押金') {
        this.$router.push({
          path: '/inpatient',
          query: {
            name: this.name,
            patName: data.name,
            patCardNo: data.patCardNo
          }
        })
      } else if (this.name === '检验报告') {
        this.$router.push({
          path: '/examine',
          query: {
            name: this.name,
            patName: data.name,
            patCardNo: data.patCardNo
          }
        })
      } else if (this.name === '检验报告查询') {
        this.$router.push({
          path: '/inspectiondetails',
          query: {
            name: this.name,
            patName: data.name,
            patCardNo: data.patCardNo,
            MZH: data.MZH
          }
        })
      } else if (this.name === '体检报告') {
        this.$router.push({
          path: '/peExamin',
          query: {
            name: this.name,
            patName: data.name,
            patCardNo: data.patCardNo,
            MZH: data.MZH
          }
        })
      } else if (this.name === '检查报告') {
        this.$router.push({
          path: '/check',
          query: {
            name: this.name,
            patName: data.name
          }
        })
      } else if (this.name === '建档绑卡') {
        this.$router.push({
          path: '/file',
          query: {
            name: this.name,
            patName: data.name
          }
        })
      } else if (this.name === '缴费列表') {
        this.$router.push({
          path: '/orderList',
          query: {
            name: this.name,
            patName: data.name,
            patCardNo: data.patCardNo
          }
        })
      } else if (this.name === '自助报道') {
        this.$router.push({
          path: '/regList',
          query: {
            name: this.name,
            patName: data.name,
            patCardNo: data.patCardNo
          }
        })
      }
    },
    input() {
      if (this.name === '建档绑卡') {
        this.$router.push({
          path: '/file',
          query: {
            isInput: 1
          }
        })
      } else {
        this.$router.push({
          path: '/input',
          query: {
            name: this.name
          }
        })
      }
    },
    code() {
      this.$router.push({
        path: '/code'
      })
    }
  }
}
</script>
<style lang="less">
.login_container {
  height: 100%;

  background-repeat: no-repeat;
  background-size: cover;
}

.login_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.login_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  /* height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px; */
}

.loginTip h1 {
  font-size: 60px;
  letter-spacing: 10px;
  padding-top: 30px;
  padding-bottom: 40px;
  font-weight: 700;
  color: #00cc66;
  text-align: center;
}

.login_main {
  margin: 15% auto;
  width: 40%;
  display: flex;
  justify-content: space-between;
  /* background-repeat: no-repeat;
  background-size: cover; */
}

.login_main .swipe {
  width: 300px;
  height: 300px;
  background: url('../../assets/sfz.png') no-repeat;
  background-size: 80% 80%;
  background-position: 50% 10px;
  border-radius: 6px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

input {
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 60px;
  background-color: transparent;
}

.login_main .code {
  width: 300px;
  height: 300px;
  border: 0;
  background: url('../../assets/code.png') no-repeat;
  background-size: 80% 80%;
  background-position: 50% 10px;
  border-radius: 6px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.login_main .chs {
  width: 300px;
  height: 300px;
  border: 0;
  background: url('../../assets/chsd.png') no-repeat;
  background-size: 50% 50%;
  background-position: 50% 10px;
  border-radius: 6px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.login_main .Wchs {
  width: 300px;
  height: 300px;
  border: 0;
  background: url('../../assets/wj.png') no-repeat;
  background-size: 50% 50%;
  background-position: 50% 10px;
  border-radius: 6px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.login_main .input {
  width: 300px;
  height: 300px;
  border: 0;
  background: url('../../assets/sg.png') no-repeat;
  background-size: 80% 80%;
  background-position: 50% 10px;
  font-size: 32px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}
</style>
