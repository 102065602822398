<template>
  <div class="count">
    <img alt="" height="40" src="../../assets/默认头像.png" width="40" />
    <div class="nameC">{{ nameC }}</div>
    <p v-if="path != '/home'">操作倒计时：{{ count }}</p>
    <el-button v-if="nameC != '未登录'" class="butt" size="medium" type="danger" @click="tui">退出</el-button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      count: '',
      timer: '',
      path: '',
      nameC: '未登录'
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  created() {
    console.log(this.$route.path)
    this.path = this.$route.path
    this.countdown()
    console.log(window.localStorage.getItem('name'))
    if (window.localStorage.getItem('name')) {
      this.nameC = window.localStorage.getItem('name')
    }
  },
  methods: {
    tui() {
      window.localStorage.clear()
      this.$router.push('/home')
      location.reload()
    },
    countdown() {
      const TIME_COUNT = 12000
      // const TIME_COUNT = 120
      this.count = TIME_COUNT
      console.log(12121)
      this.timer = setInterval(() => {
        if (this.count > 0 && this.count <= TIME_COUNT) {
          this.count--
          if (this.count == 0) {
            this.$router.push('/home')
            window.localStorage.clearItem()
            console.log('input')
          }
        } else {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.count {
  padding-top: 1%;
  font-size: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  color: white;

  p {
    color: red;
    margin: 0 15px;
  }

  .nameC {
    padding-right: 20px;
  }
}

.butt {
  width: 5%;
  height: 80px;
  font-size: 40px;
  text-align: center;
  margin: 0 30px;
}
</style>
